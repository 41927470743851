// color-palette
@white: #ffffff;

@gray50: #f9f9fa;
@gray100: #f6f7f8;
@gray200: #e9ecee;
@gray300: #cbcdd2;
@gray400: #b3b5ba;
@gray500: #9ea1a7;
@gray600: #838589;
@gray700: #6e6e72;
@gray800: #5e5e61;
@gray900: #464648;
@gray950: #2e2e31;

@violet40: #f5f4ff;
@violet50: #eeedff;
@violet60: #e5e2ff;
@violet70: #cfcdff;
@violet80: #b6b2ff;
@violet90: #a4a1f5;
@violet100: #837aff;
@violet110: #6b62ed;
@violet120: #5148cf;

@blue40: #f3f6ff;
@blue50: #ecf0ff;
@blue60: #d0dbff;
@blue70: #9fb5ff;
@blue80: #7b99ff;
@blue90: #6e8eff;
@blue100: #4269f5;
@blue110: #274ed9;
@blue120: #183ab7;

@red90: #ff5252;
@red110: #e80000;

@orange80: #ffb444;
@orange100: #ff7a00;

@green70: #14f200;
@green100: #00c944;

@skyBlue80: #1badff;
@skyBlue100: #006ded;

@pending: #ffd600;
@delete: #ff0000;
@transition: #0085ff;
@guide: #ff00e6;

@special01: #d9dbde;
@special02: #ffece7;

// box-shadow
@card01: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
@card02: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
@card03:
  0px 2px 15px 0px rgba(0, 0, 0, 0.05),
  0px 12px 20px 0px rgba(0, 0, 0, 0.2);

// default value
@default-fz: 1.4rem;
@default-lh: 1.4;
@default-radius: 12px;
@admin-primary: @violet100;
@bg-normal: @white;
@bg-alternative: @gray200;
@text-main: @gray950;
@text-sub: @gray800;
@assistive: @gray500;
@line: @gray200;
@success: @green100;
@positive: @skyBlue100;
@error: @red110;
@warning: @orange100;

// mixin
.head-typo (@head-fz: @default-fz, @head-lh: @default-lh) {
  font-size: @head-fz;
  line-height: @head-lh;
  font-weight: 700;
}
.body-typo (@body-fz: @default-fz, @body-lh: @default-lh) {
  font-size: @body-fz;
  line-height: @body-lh;
  font-weight: 400;
}
.flex-row (@justify: center, @gap: 0) {
  display: flex;
  align-items: center;
  justify-content: @justify;
  gap: @gap;
}
.flex-column (@gap: 0) {
  display: flex;
  flex-direction: column;
  gap: @gap;
}
.radius (@radius: @default-radius) {
  border-radius: @radius;
}
.button (@width: 120px, @height: 38px, @radius: 6px, @bg-color: @white, @border: none, @color: @text-main) {
  width: @width;
  height: @height;
  border-radius: @radius;
  background-color: @bg-color;
  border: @border;
  color: @color;
  outline: none;
  cursor: pointer;
}
.disabled (@color: @gray600, @bg-color: @gray200) {
  pointer-events: none;
  background-color: @bg-color;
  opacity: 0.4;
  color: @color;
  border: none;
}
