@import '../src/style-vars.less';
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable-dynamic-subset.min.css');
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css');

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family:
    'Pretendard Variable',
    Pretendard,
    -apple-system,
    BlinkMacSystemFont,
    system-ui,
    Roboto,
    'Helvetica Neue',
    'Segoe UI',
    'Apple SD Gothic Neo',
    'Noto Sans KR',
    'Malgun Gothic',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    sans-serif;
  margin: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-size: 62.5%;
}
body {
  font-size: 1.4rem;
  line-height: 1.4;
  letter-spacing: -0.06rem;
  -webkit-font-smoothing: antialiased;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
textarea:not([rows]) {
  min-height: 10ex;
}
button {
  transition:
    background-color 0.2s ease,
    opacity 0.2s ease;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
h1,
h2,
h3,
h4,
button,
input,
label {
  line-height: 1.1;
}
h1,
h2,
h3,
h4 {
  text-wrap: balance;
}
a:not([class]) {
  text-decoration-skip-ink: auto;
  color: currentColor;
}

ul[role='list'],
ol[role='list'] {
  list-style: none;
  padding: 0;
}

img,
picture {
  width: fit-content;
  max-width: 100%;
  display: block;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
:target {
  scroll-margin-block: 5ex;
}

:root {
  // mat menu style
  --mat-menu-item-label-text-font: 'Pretendard Variable', Pretendard, sans-serif;
  --mat-menu-item-label-text-line-height: 1.46;
  --mat-menu-item-label-text-size: 1.3rem;
  --mat-menu-item-label-text-tracking: -0.06rem;
  --mat-menu-item-label-text-weight: 400;
  --mat-menu-container-shape: 10px;
  --mat-app-elevation-shadow-level-2: @card01;
  --mat-menu-container-color: @white;
  --mat-menu-item-leading-spacing: 8px;
  --mat-menu-item-trailing-spacing: 8px;
  --mat-menu-item-hover-state-layer-color: @violet50;

  // mat checkbox style
  --mdc-checkbox-state-layer-size: 0;
  --mdc-checkbox-selected-icon-color: @admin-primary;
  --mdc-checkbox-selected-focus-icon-color: @admin-primary;
  --mdc-checkbox-selected-hover-icon-color: @admin-primary;
  --mdc-checkbox-unselected-icon-color: @gray600;
  --mdc-checkbox-unselected-focus-icon-color: @gray600;
  --mdc-checkbox-unselected-hover-icon-color: @gray600;

  // mat dialog style
  --mat-dialog-container-max-width: 100%;
  --mdc-dialog-container-shape: 16px;
  --mdc-dialog-container-color: @white;

  // mat tabs style
  --mat-tab-header-active-hover-label-text-color: @text-main;
  --mat-tab-header-active-focus-label-text-color: @text-main;
  --mat-tab-header-active-label-text-color: @text-main;
  --mat-tab-header-active-focus-indicator-color: @admin-primary;
  --mat-tab-header-active-hover-indicator-color: @admin-primary;
  --mat-tab-header-inactive-label-text-color: @gray400;
  --mat-tab-header-inactive-hover-label-text-color: @gray400;
  --mdc-tab-indicator-active-indicator-color: @admin-primary;
  --mat-tab-header-label-text-font: 'Pretendard Variable', Pretendard, sans-serif;
  --mat-tab-header-label-text-size: 1.6rem;
  --mat-tab-header-label-text-tracking: 0.06rem;
  --mat-tab-header-label-text-line-height: 1.37;
  --mat-tab-header-label-text-weight: 700;
  --mdc-secondary-navigation-tab-container-height: 54px;
}

::ng-deep {
  // mat menu style
  .mat-mdc-menu-panel {
    margin-top: 4px;
  }
  .mat-mdc-menu-content {
    padding: 8px !important;
  }
  .mat-mdc-menu-item {
    min-height: 32px !important;
    border-radius: 6px;
    transition: background-color 0.2s ease;
  }
  .mat-mdc-menu-item:not([disabled]):hover {
    color: @violet110;
    .mat-mdc-menu-item-text {
      font-weight: 700 !important;
    }
  }

  // mat check-box style
  .mdc-checkbox__ripple {
    visibility: hidden;
  }
  .mat-mdc-checkbox {
    width: 100%;
    .mat-internal-form-field {
      width: 100%;
    }
  }
  .mdc-checkbox {
    padding: 0;
    & + .mdc-label {
      .flex-row (space-between);
      font-size: 1.4rem;
      font-weight: 700;
      padding-left: 8px;
      flex: 1;
    }
  }
  .mdc-checkbox__background {
    border: 1px solid @gray600 !important;
    border-radius: 4px !important;
  }
  .mat-mdc-checkbox-touch-target {
    width: 18px !important;
    height: 18px !important;
  }

  // mat tabs style
  .mdc-tab__ripple::before {
    opacity: 0 !important;
  }
  .mat-mdc-tab-body {
    padding: 24px 0 16px;
  }
  .mat-mdc-tab-body-wrapper {
    padding: 0 24px;
  }
}
